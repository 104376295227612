export const controls = {
    date: {
        label: 'Date Done',
        elementType: 'date',
        elementConfig:{
                type: 'date',
                placeholder:''
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
}
