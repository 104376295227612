import axios from 'axios'
import { refreshToken } from '../utils/function';

let store;

export const injectStoreWithCredentials = _store => {
    store = _store
}


const axiosInstance =  () => {

    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
        },

    })

    instance.interceptors.request.use((config) => {
        const token = store.getState().credentials.token;
        config.headers.Authorization = `bearer ${token}`;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    instance.interceptors.response.use(
        (response) => {return response },
        async (err) => {
            const error = err.response;
            if (error.status===401 && error.config && !error.config.__isRetryRequest) {
                const newTokens = await refreshToken();
                if ( newTokens && newTokens.status === 200){
                        return instance(error.config);
                    }
        }
        return Promise.reject(err);

    });

    return instance;
}

export default axiosInstance;
