import { configureStore } from '@reduxjs/toolkit'
import formInfoReducer from '../features/loading/loadingSlice.js';
import credentialsReducer from '../features/credentials/credentialsSlice.js';
import tasksReducer from '../features/tasks/tasksSlice.js';

export default configureStore({
    reducer: {
        formInfo:formInfoReducer,
        credentials: credentialsReducer,
        tasks: tasksReducer,
    }
})
