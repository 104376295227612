export const newTaskForm = {
    task: {
        label:"Tâche",
        elementType: 'textarea',
        elementConfig:{
                type: 'textarea',
                placeholder:'Enter the new Task'
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
    dateToFinish: {
        label: 'Date Limite',
        elementType: 'date',
        elementConfig:{
                type: 'date',
                placeholder:'Date à laquelle la tâche doit être terminée'
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
}
