import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Buffer } from 'buffer';

import { noCredentialsRequest, withCredentialsRequest, saveTokens, removeTokens } from '../../dataBaseConnexion/utils/function';


const initialState = {
    refreshToken:'',
    refreshTokenExpireTime: '',
    token:'',
    tokenExpireTime: '',
    user: {},
    timeOutId:null,
    logIn: false,
}

export const userConnect = createAsyncThunk(
    'credentials/userConnect', async (authCredentials, thunkAPI) => {
        removeTokens();
        const url = process.env.REACT_APP_APIHOST + '/api/V1/auth/login';
        return noCredentialsRequest(url, authCredentials);
    }
)

export const userRegister = createAsyncThunk(
    'credentials/userRegister', async (authCredentials, thunkAPI) => {
        removeTokens();
        const url = process.env.REACT_APP_APIHOST + '/api/V1/user/newuser'
        return noCredentialsRequest(url, authCredentials);;
    }
)

export const userLastLogIn = createAsyncThunk(
    'credentials/userLastLogIn', async (authCredentials, thunkAPI) => {
        const url = process.env.REACT_APP_APIHOST + '/api/V1/auth/loginsuccessful'
        return withCredentialsRequest(url,authCredentials);
    }
)

export const userDisconnect = createAsyncThunk(
    'credentials/userDisconnect', async (args, thunkAPI) => {
        thunkAPI.dispatch(logOut());
    }
)

export const checkAuthTimeOut = createAsyncThunk(
    'credentials/checkAuthTimeOut', async (timeOut, thunkAPI) => {
        let timeOutId = thunkAPI.getState().credentials.timeOutId;
        if (timeOutId) { clearTimeout(timeOutId) }
        timeOutId = setTimeout(() => {
                thunkAPI.dispatch(userDisconnect());
            },  1000*  parseInt(timeOut.slice(0,-1)) );
        thunkAPI.dispatch(setTimeOutId(timeOutId));
        saveTokens( thunkAPI.getState().credentials.token,
                    thunkAPI.getState().credentials.refreshToken,
                    thunkAPI.getState().credentials.user,
                    thunkAPI.getState().credentials.tokenExpireTime,
                    thunkAPI.getState().credentials.refreshTokenExpireTime
        );
        return timeOutId
})

export const checkAuthState = createAsyncThunk(
    'credentials/checkAuthState', async (args,thunkAPI) => {
        const savedStorage = localStorage.getItem('__Zat');
        if (!savedStorage){
            thunkAPI.dispatch(userDisconnect());
        } else {
            const tokenDecoded = JSON.parse(Buffer.from(savedStorage,'base64'));
            const {user, token,refreshtoken } = tokenDecoded;
            const {tokenduration, refreshtokenduration,expat } = tokenDecoded;
            const expAt = new Date(expat);
            if (expAt > new Date()){
                const remainingTime= (expAt - new Date())/1000;
                thunkAPI.dispatch(newCredentials( token,tokenduration, refreshtoken,refreshtokenduration, user.id, user.email, user.lastlogin));
                thunkAPI.dispatch(checkAuthTimeOut(remainingTime + 's'));
            } else {
                thunkAPI.dispatch(userDisconnect())
            }
        }
})

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState: initialState,
    reducers: {
        newCredentials: {
            reducer (state,{payload}) {
                state.token = payload.token;
                state.tokenExpireTime = payload.tokenExpireTime;
                state.refreshToken = payload.refreshToken;
                state.refreshTokenExpireTime = payload.refreshTokenExpireTime;
                state.user = payload.user;
                state.logIn = payload.logIn;


            },
            prepare(token,tokenExpireTime,refreshToken,refreshTokenExpireTime,id,email,lastlogin){
                return {
                    payload: {
                        token: token,
                        tokenExpireTime:tokenExpireTime,
                        refreshToken:refreshToken,
                        refreshTokenExpireTime:refreshTokenExpireTime,
                        user: { id: id,
                                email: email,
                                lastlogin: lastlogin
                        },
                        logIn: true,
                    }
                }
            }
        },
        renewTokens: {
            reducer (state,{payload}) {
                state.token = payload.token;
                state.tokenExpireTime = payload.tokenexpiretime;
                state.refreshToken = payload.refreshtoken;
                state.refreshTokenExpireTime = payload.refreshtokenexpiretime;
            },
            prepare(data){
                return {
                    payload: data
                }
            }
        },
        newToken:{
                    reducer(state,action) {
                        state.token = action.payload
                    },
                    prepare (token) {
                        return {
                            payload : token
                        }
                    }
                },
        logOut:{
                    reducer(state,action) {
                        state.logIn = false;
                        state.refreshToken = '';
                        state.refreshTokenExpireTime = '';
                        state.token = '';
                        state.tokenExpireTime = '';
                        state.timeOutId = null;
                        state.user = {};
                        removeTokens();
                    },
                    prepare () {
                        return {
                            payload : ''
                        }
                    }
                },
        newRefreshToken:{
                            reducer(state,action) {
                                return  state.refreshToken = action.payload
                            },
                            prepare (refreshToken) {
                                return {
                                    payload : refreshToken
                                }
                            }

                    },
        logOut2: {
                        reducer(state,action) {
                            console.log('debut logout')


                        },
                        prepare (args) {
                            return {
                                    payload : ''
                        }
            }

        },
        setTimeOutId:{
            reducer(state,action) {
                state.timeOutId = action.payload
            },
            prepare (timeOutId) {
                return {
                    payload : timeOutId
                }
            }
        },
    },
    extraReducers : {
        [userRegister.fulfilled]: (state, {payload}) => {
            if (payload.success){
                state.token = payload.token;
                state.refreshToken = payload.refreshtoken;
                state.tokenExpireTime = payload.tokenExpireTime;
                state.refreshTokenExpireTime = payload.refreshTokenExpireTime;
                state.user = payload.user;
                state.logIn = true;
            }
        },
        [checkAuthTimeOut.rejected]: (state, {error}) => {
            console.log(error)
        },
        [userDisconnect.rejected]: (state, {error}) => {
            console.log(error)
        },
        [checkAuthState.rejected]: (state, {error}) => {
            console.log(error)
        },
    }
})


export const {  newCredentials, newToken, newRefreshToken, renewTokens, logOut, setTimeOutId } = credentialsSlice.actions

export const selectToken = (state) => state.credentials.token;
export const selectTokenExpireTime = (state) => state.credentials.tokenExpireTime;
export const selectRefreshToken = (state) => state.credentials.refreshToken;
export const selectRefreshTokenExpireTime = (state) => state.credentials.refreshTokenExpireTime;

export const selectUser = (state) => state.credentials.user;
export const selectLogIn = (state) => state.credentials.logIn;

export default credentialsSlice.reducer
