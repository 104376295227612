import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Main from '../../components/Main/Main';

import classes from './Layout.module.css';

function Layout(props) {

    return (
        <React.Fragment>
            <div className={classes.layout} >
                <Header />
                <Main>
                    {props.children}
                </Main>
                <Footer />
            </div>

        </React.Fragment>

    );
}

export default Layout;
