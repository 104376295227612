import React, { useState } from 'react';

import Button from '../Button/Button';
import Input from '../Input/Input';
import { controls as DateDoneDataParams} from './DateDoneDataParams';

import classes from './ConfirmationDone.module.css';

function ConfirmationDone(props) {
    const [dateDoneForm, setdateDoneForm] = useState(DateDoneDataParams);
    const [isFormValid, setisFormValid] = useState(true);

    // const formInError = useSelector(selectFormInError);
    const formInError = false;
    // const libelleError = useSelector(selectlibelleError);
    const libelleError = '';

    const confirmationButtonHandle = (e) => {
        e.preventDefault();
        const dateDone= document.querySelectorAll('input[type=date]')[0].value
        props.confirmationBtn(dateDone);
    }
    const inputChangeHandler = (event, element) => {
        const updatedNewTaskForm={...dateDoneForm};
        const updatedNewTaskFormElement={...updatedNewTaskForm[element]};

        updatedNewTaskFormElement.value=event.target.value;
        updatedNewTaskFormElement.touched=true;
        updatedNewTaskFormElement.valid= checkValidity(updatedNewTaskFormElement.value,updatedNewTaskFormElement.validation);

        updatedNewTaskForm[element] = updatedNewTaskFormElement;

        let formIsValid = true;
        for (const field in updatedNewTaskForm ) {
                formIsValid = updatedNewTaskForm[field].valid && formIsValid;
        }

        setdateDoneForm(updatedNewTaskForm);
        setisFormValid(formIsValid);
    }

    const checkValidity = (value, rules) => {

        if (!rules) {
            return true;
        }

        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    const formElementArray = [];

    for (const key in dateDoneForm) {
        if (Object.hasOwnProperty.call(dateDoneForm, key)) {
            formElementArray.push({
                id:key,
                config: dateDoneForm[key]
            })
        }
    }

    const today = new Date();
    const defaultValue = new Date(today).toISOString().split('T')[0]

    let form =(
        <div className= {classes.Form}>

            {formInError &&
                <label className={classes.Error}>{libelleError}</label>
            }
            <form>
                {formElementArray.map( element =>  {
                    element.config.elementConfig.defaultValue = defaultValue;
                    return <Input
                            key={element.id}
                            elementType={element.config.elementType}
                            elementConfig={element.config.elementConfig}
                            invalid={!element.config.valid}
                            checkValidation={element.config.validation}
                            touched={element.config.touched}
                            changed={(event) => inputChangeHandler(event, element.id)}
                    />
                })}
            </form>
        </div>
    )

    return (
        <div className={classes.Confirmation} >
            <div className={classes.Title}> Task Done</div>
            <div className={classes.Form}>
                {form}
            </div>


            <div className={classes.Buttons}>
                <Button btnType="Info BlackText"
                                    clicked={(e) => props.annulationBtn(e)}>
                                    Annuler
                </Button>
                <Button btnType="Primary BlackText"
                                    disabled={!isFormValid}
                                    clicked={(e) => confirmationButtonHandle(e)}>
                                    Confirmer
                </Button>
            </div>
        </div>
    );
}

export default ConfirmationDone;
