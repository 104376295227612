import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { registerForm, confirmPasswords } from './registerDataParams';
import { userRegister, checkAuthTimeOut } from '../../features/credentials/credentialsSlice';
import { selectFormInError, selectlibelleError  } from '../../features/loading/loadingSlice';

import classes from './Register.module.css';

function Register(props) {

    const [isFormValid, setisFormValid] = useState(false);
    const [signUpForm, setsignUpForm] = useState(registerForm);

    const formInError = useSelector(selectFormInError);
    const libelleError = useSelector(selectlibelleError);
    const dispatch = useDispatch();

    const formSubmit = async (event) => {
        event.preventDefault();

        const email = signUpForm.email.value;
        const password = signUpForm.password.value;
        const salt = bcrypt.genSaltSync(10);
        const hashPassword = bcrypt.hashSync(password + email, salt);

        const authCrebdentials ={
            email: email,
            password: hashPassword
        };

        dispatch(userRegister(authCrebdentials))
            .then ((res) => {
                if ( res.payload.success) {
                    dispatch(checkAuthTimeOut(res.payload.refreshTokenExpireTime));
                    props.registerButtonClick();
                }
            })
    }

    const inputChangeHandler = (event, element) => {

        const updatedSignUpForm={...signUpForm};
        const updatedSignUpFormElement={...updatedSignUpForm[element]};

        const value = event.target.value;
        const rules = updatedSignUpFormElement.validation;

        updatedSignUpFormElement.value=value;
        updatedSignUpFormElement.touched=true;
        updatedSignUpFormElement.valid=checkValidity(value,rules);

        updatedSignUpForm[element] = updatedSignUpFormElement;

        if (rules.confirmPassword) {
            const isValid = confirmPasswords(element, value, signUpForm) && updatedSignUpFormElement.valid;
            const SignUpFormPassword={...updatedSignUpForm['password']};
            const SignUpFormPasswordConfirmation={...updatedSignUpForm['passwordconfirmation']};
            SignUpFormPassword.valid = isValid && SignUpFormPassword.touched;
            SignUpFormPasswordConfirmation.valid = isValid && SignUpFormPasswordConfirmation.touched;
            updatedSignUpForm['password'] = SignUpFormPassword;
            updatedSignUpForm['passwordconfirmation'] = SignUpFormPasswordConfirmation;
        }

        let formIsValid = true;
        for (const field in updatedSignUpForm ) {
                formIsValid = updatedSignUpForm[field].valid && formIsValid;
        }

        setisFormValid(formIsValid);
        setsignUpForm(updatedSignUpForm)
    }

    const checkValidity = (value, rules) => {

        if (!rules) {
            return true;
        }

        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if (rules.isEmail) {

            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    const formElementArray = [];

    for (const key in signUpForm) {
        if (Object.hasOwnProperty.call(signUpForm, key)) {
            formElementArray.push({
                id:key,
                config: signUpForm[key]
            })
        }
    }

    let form=(
        <div className= {classes.Form}>
            <div className={classes.Title}>
                <h1>Register</h1>
                <img src='register.jpg'
                        alt="Register" height="30px"></img>
            </div>

            {formInError &&
                <label className={classes.Error}>{libelleError}</label>
            }

            <form onSubmit= {formSubmit}>
            {formElementArray.map( element =>  (
                <Input
                        key={element.id}
                        elementType={element.config.elementType}
                        elementConfig={element.config.elementConfig}
                        value={element.config.value}
                        invalid={!element.config.valid}
                        checkValidation={element.config.validation}
                        touched={element.config.touched}
                        changed={(event) => inputChangeHandler(event, element.id)}
                />
            ))}
                <div className={classes.RegisterButtonDiv}>
                    <Button btnType="Outline-Success"
                            disabled={!isFormValid}>
                            Register
                    </Button>
                </div>
            </form>
        </div>
    )

    return (
        <div className={classes.register}>
            {form}
            <div className="Button">
                <Button btnType="Normal Info" clicked={props.homeClick}> Home </Button>
                <Button btnType="Normal Info" clicked={props.logInClick}> Log In </Button>
            </div>

        </div>
    );
}

export default Register;
