import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux';

import { userConnect, userRegister, userDisconnect } from '../credentials/credentialsSlice';
import { getAllTasks, newTask, deleteTask, taskDone, resetTasks } from '../tasks/tasksSlice';

const initialState = {
    success: false,
    formInError: false,
    libelleError: '',
};

export const resetAll = createAsyncThunk(
    'formInfo/resetUser', async ( thunkAPI) => {
        const dispatch = useDispatch();
        dispatch(resetTasks());
        dispatch(resetForm());
        dispatch(userDisconnect());
    }
)

export const loadingSlice = createSlice({
    name: 'formInfo',
    initialState: initialState,
    reducers: {
        resetForm : {
            reducer (state) {
                state.success = false;
                state.formInError = false;
                state.libelleError = '';
            },
        },
        setFormInError : {
            reducer (state,{payload}) {
                state.success = payload.success;
                state.formInError = payload.formInError;
                state.libelleError = payload.libelleError;
            },
            prepare(libelleError){
                return {
                    payload: {
                        success : false,
                        formInError : true,
                        libelleError : libelleError
                    }
                }
            }
        }
    },
    extraReducers : {
        [userConnect.pending]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [userRegister.pending]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [getAllTasks.pending]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [newTask.pending]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [deleteTask.pending]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [taskDone.pending]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [userDisconnect.fulfilled]: (state) => {
            state.success = false;
            state.formInError = false;
            state.libelleError = '';
        },
        [userConnect.fulfilled]: (state, {payload}) => {
            if (!payload.success) {
                state.success = false;
                state.formInError = true;
                state.libelleError = payload.error;
            } else {
                state.success = true;
            }
        },
        [userRegister.fulfilled]: (state, {payload}) => {
            if (!payload.success) {
                state.success = false;
                state.formInError = true;
                state.libelleError = payload.error;
            } else { state.success = true; }
        },
        [getAllTasks.fulfilled]: (state, {payload}) => {
            if (!payload.success) {
                state.success = false;
                state.formInError = true;
                state.libelleError = payload.error;
            } else { state.success = true; }
        },
        [newTask.fulfilled]: (state, {payload}) => {
            if (!payload.success) {
                state.success = false;
                state.formInError = true;
                state.libelleError = payload.error;
            } else { state.success = true; }
        },
        [userConnect.rejected]: (state, {error}) => {
            state.success = false;
            state.formInError = true;
            state.libelleError = error.message;
        },
        [userRegister.rejected]: (state, {error}) => {
            state.success = false;
            state.formInError = true;
            state.libelleError = error.message;
        },
        [getAllTasks.rejected]: (state, {error}) => {
            state.success = false;
            state.formInError = true;
            state.libelleError = error.message;
        },
        [newTask.rejected]: (state, {error}) => {
            state.success = false;
            state.formInError = true;
            state.libelleError = error.message;
        },
        [deleteTask.rejected]: (state, {error}) => {
            state.success = false;
            state.formInError = true;
            state.libelleError = error.message;
        },
    }
})

export const { resetForm, setFormInError } = loadingSlice.actions
export const selectFormInError = (state) => state.formInfo.formInError;
export const selectlibelleError = (state) => state.formInfo.libelleError;

export default loadingSlice.reducer
