import React, { useEffect } from 'react';
import DataTable from 'datatables.net';

import Header from './Header';
import Body from './Body';

import classes from './TableData.module.css';
import  'datatables.net-jqui/css/dataTables.jqueryui.min.css';

function TableData(props) {

    const menuValues = [ ...props.menu]
    menuValues[ menuValues.length -1] = -1

    useEffect(() => {

        new DataTable( '#'+props.id,{
            destroy: true,
            searching: props.searching,
            ordering:  props.ordering,
            paging: props.paging,
            pagingType: props.pagingType,
            columnDefs:props.columnDefs,
            order: [[ props.order.column, props.order.order]],
            lengthMenu: [
                menuValues,
                props.menu,
            ],
            "language": props.languages
        });

    }, [props,menuValues]);
//
    return (
        <div className={classes.Datatable}>
            <table id={props.id} className="display" >
                <Header header={props.header}/>
                <Body data={props.data} />
            </table>
        </div>
    );
}

export default TableData;
