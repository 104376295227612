import React from 'react';

import classes from './Spinner.module.css';

const Spinner = () => {
    return (
        <div className={classes.opposites}>
	        <div className={[classes.opposites ,classes.bl].join(' ')}></div>
	        <div className={[classes.opposites, classes.tr].join(' ')}></div>
	        <div className={[classes.opposites, classes.br].join(' ')}></div>
	        <div className={[classes.opposites, classes.tl].join(' ')}></div>
        </div>

    )
}

export default Spinner;
