import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { withCredentialsRequest } from '../../dataBaseConnexion/utils/function';
import { userDisconnect } from '../credentials/credentialsSlice';

export const getAllTasks = createAsyncThunk(
    'tasks/getAllTasks', async (args,thunkAPI) => {
        const url = process.env.REACT_APP_APIHOST + '/api/V1/tasks/getall';
        const users_id = thunkAPI.getState().credentials.user.id;
        return withCredentialsRequest(url,{ users_id });
    }
)

export const newTask = createAsyncThunk(
    'tasks/newTask', async (newTaskValues,thunkAPI) => {
        const url = process.env.REACT_APP_APIHOST + '/api/V1/tasks/newtask';
        const users_id = thunkAPI.getState().credentials.user.id;
        newTaskValues.users_id = users_id;
        return withCredentialsRequest(url,newTaskValues);
    }
)

export const deleteTask = createAsyncThunk(
    'tasks/deleteTask', async (tasks_id,thunkAPI) => {
        const url = process.env.REACT_APP_APIHOST + '/api/V1/tasks/deletetask';
        return withCredentialsRequest(url,{ tasks_id : tasks_id });
    }
)

export const taskDone = createAsyncThunk(
    'tasks/taskDone', async (taskDone,thunkAPI) => {
        const tasks = thunkAPI.getState().tasks.tasks;
        const task = tasks.find(task => task.tasks_id === taskDone.tasks_id);
        taskDone.tasks_desc = task.tasks_desc;
        taskDone.tasks_date_limite = task.date_limite_yyyymmddhh24miss.substring(0,4) + '-' + task.date_limite_yyyymmddhh24miss.substring(4,6) + '-' + task.date_limite_yyyymmddhh24miss.substring(6,8);
        const url = process.env.REACT_APP_APIHOST + '/api/V1/tasks/updatetask';
        return withCredentialsRequest(url,taskDone );
    }
)

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: []
    },
    reducers: {
        resetTasks : (state) => {
            state.tasks= [];
        },
    },
    extraReducers : {
        [getAllTasks.fulfilled]: (state, {payload}) => {
            if (payload.success){
                state.tasks = payload.number === 0 ? [] : payload.tasks;
            }
        },
        [deleteTask.fulfilled]: (state, {payload}) => {
            if (payload.success){
                state.tasks = payload.number === 0 ? [] : payload.tasks;
            }
        },
        [taskDone.fulfilled]: (state, {payload}) => {
            if (payload.success){
                state.tasks = payload.number === 0 ? [] : payload.tasks;
            }
        },
        [userDisconnect.fulfilled]: (state, {payload}) => {
                state.tasks = [] ;
        },
    }
});

export const selectTasks = (state) => state.tasks.tasks;
export const { resetTasks } = tasksSlice.actions

export default tasksSlice.reducer
