import React from 'react';

import Button from '../../components/Button/Button';

import classes from './Home.module.css';

function Home(props) {
    return (
        <div className={classes.home}>
            <div className="Button">
                <Button btnType="Normal Info" clicked={props.logInClick}> Log In </Button>
                <Button btnType="Normal Info" clicked={props.registerClick}> Register </Button>
            </div>

        </div>
    );
}

export default Home;
