import React from 'react';
import classes from './Header.module.css';

function Header({title, logeedIn}) {
    return (
        <div className={classes.header}>
            <h1>
                TasksMania
            </h1>
        </div>
    );
}

export default Header;
