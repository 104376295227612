import React from 'react';

import classes from './Modal.module.css';

const Modal = (props) => {

    // const classDiv = classes.Modal;
    const classDiv = props.show ? classes.Modal : classes.ModalHide
    return (
        <React.Fragment>
            <div className={classDiv}>
                {props.children}
            </div>
        </React.Fragment>
    );

}

export default Modal;
