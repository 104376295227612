import React from 'react';

import Button from '../Button/Button';

import classes from './ConfirmationSuppression.module.css';

const ConfirmationSuppression = (props) => {
    return (
        <div className={classes.Confirmation}>
            <div className={classes.Title}> Suppression</div>
            <div className={classes.Text}>   Etes-vous sûr de vouloir supprimer cette tâche?
                    Vous ne pourrez plus revenir en arrière.
            </div>
            <div className={classes.Buttons}>
                <Button btnType="Info BlackText"
                                clicked={(e) => props.annulationBtn(e)}>
                                    Annuler
                </Button>
                <Button btnType="Danger BlackText"
                                    clicked={(e) => props.confirmationBtn(e)}>
                                    Supprimer
                </Button>
            </div>
        </div>

    );
};

export default ConfirmationSuppression;
