import React from 'react';

import classes from './Footer.module.css';

function Footer(props) {
    const newDate = new Date();
    const year = newDate.getFullYear();

    return (
        <footer className={classes.footer} >
              <span>&copy; JPDB</span> {year}
        </footer>
    );
}

export default Footer;
