export const controls = {
    email: {
        elementType: 'input',
        elementConfig:{
                type: 'email',
                placeholder:'Enter Your Email'
        },
        value: '',
        validation: {
            required: true,
            isEmail: true
        },
        valid: false,
        touched: false
    },
    password: {
        elementType: 'input',
        elementConfig:{
                type: 'password',
                placeholder:'Enter Your Password'
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    }
}
