import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import { newTaskForm as newTaskFormParams } from './newTaskDataParams';
import Input from '../../components/Input/Input';
import Spinner from '../../components/Spinner/Spinner';

import { selectFormInError,selectlibelleError } from '../../features/loading/loadingSlice';
import { newTask } from '../../features/tasks/tasksSlice';

import classes from './NewTask.module.css';

function NewTask(props) {

    const [isFormValid, setisFormValid] = useState(false);
    const [newTaskForm, setnewTaskForm] = useState(newTaskFormParams);
    const [isLoading, setisLoading] = useState(false);

    const formInError = useSelector(selectFormInError);
    const libelleError = useSelector(selectlibelleError);
    const dispatch = useDispatch();

    const formOnCommit = (event) => {
        event.preventDefault();
        const tasks_date_limite = newTaskForm.dateToFinish.value;
        const tasks_desc = newTaskForm.task.value;
        setisLoading(true);
        dispatch(newTask({tasks_desc,tasks_date_limite}))
            .then ((res) => {
                setisLoading(false);
                if (res.payload?.success) {
                    props.sauvegardeButtonClick(event,newTaskForm);
                }
            })
    }

    const inputChangeHandler = (event, element) => {
        const updatedNewTaskForm={...newTaskForm};
        const updatedNewTaskFormElement={...updatedNewTaskForm[element]};

        updatedNewTaskFormElement.value=event.target.value;
        updatedNewTaskFormElement.touched=true;
        updatedNewTaskFormElement.valid= checkValidity(updatedNewTaskFormElement.value,updatedNewTaskFormElement.validation);

        updatedNewTaskForm[element] = updatedNewTaskFormElement;

        let formIsValid = true;
        for (const field in updatedNewTaskForm ) {
                formIsValid = updatedNewTaskForm[field].valid && formIsValid;

        }

        setnewTaskForm(updatedNewTaskForm);
        setisFormValid(formIsValid);
    }

    const checkValidity = (value, rules) => {

        if (!rules) {
            return true;
        }

        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    const formElementArray = [];

    for (const key in newTaskForm) {
        if (Object.hasOwnProperty.call(newTaskForm, key)) {
            formElementArray.push({
                id:key,
                config: newTaskForm[key]
            })
        }
    }

    let form =(
        <div className= {classes.Form}>
            <div className={classes.Title}>
                <h1>New Task</h1>
                <img src='newtask.jpg'
                        alt="New Task" height="30px"></img>
            </div>

            {formInError &&
                <label className={classes.Error}>{libelleError}</label>
            }
            <form onSubmit= {(e) => formOnCommit(e)}>
                {formElementArray.map( element =>  {
                    return <Input
                            key={element.id}
                            label={element.config.label}
                            elementType={element.config.elementType}
                            elementConfig={element.config.elementConfig}
                            value={element.config.value}
                            invalid={!element.config.valid}
                            checkValidation={element.config.validation}
                            touched={element.config.touched}
                            changed={(event) => inputChangeHandler(event, element.id)}
                    />
                })}
                    <div className={classes.SauvegardeButtonDiv}>
                        <Button btnType="Outline-Success"
                                disabled={!isFormValid}>
                                Sauvegarder
                        </Button>
                    </div>
            </form>
        </div>
    )
    return (
        <React.Fragment>
            { isLoading && <Spinner/> }
            { !isLoading &&
                <div className={classes.NewTask}>
                    {form}
                    <div className={classes.NavigationButton}>
                        <div className={classes.DisconnectButton}>
                                <div className="Button">
                                    <Button btnType="Normal Info"
                                    clicked={props.disconnectButtonClick}> Disconnect </Button>
                                </div>
                        </div>
                        <div className={classes.TasksButton}>
                                <div className="Button">
                                    <Button btnType="Normal Info"
                                    clicked={props.tasksButtonClick}> Tasks </Button>
                                </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default NewTask;
