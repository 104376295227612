import React from 'react';

function Body(props) {
    let body = []
    const makeTableRow = (row) => {
        const tableRow = [] ;
        for (const col of row) {
            tableRow.push(<td key={Math.random()+{col}}> {col} </td>)
        }
        body.push(<tr key={Math.random()}>{tableRow}</tr>)
    }

    for (const row of props.data) {
        makeTableRow(row);
    }
    return (
        <tbody>
            {body}
        </tbody>
    );
}

export default Body;
