import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import TableData from '../../components/TableData/TableData';
import { getAllTasks, deleteTask, taskDone } from '../../features/tasks/tasksSlice';
import Spinner  from '../../components/Spinner/Spinner';
import Backdrop from '../../components/Backdrop/Backdrop';
import ConfirmationSuppression from '../../components/ConfirmationSuppression/ConfirmationSuppression';
import Modal from '../../components/Modal/Modal';
import TaskDone from '../../components/ConfirmationDone/ConfirmationDone';
import { selectFormInError, selectlibelleError } from '../../features/loading/loadingSlice';
import { selectLogIn } from '../../features/credentials/credentialsSlice';

import classes from './Tasks.module.css';
import DefaultLanguageOptions from '../../components/TableData/Languages';

function Tasks(props) {

    const [dataFilterState, setdataFilterState] = useState('tous');
    const [showDataTable, setshowDataTable] = useState(true);
    const [isLoading, setisLoading] = useState(null);
    const [backdropShow, setbackdropShow] = useState(false);
    const [modalTaskDone, setmodalTaskDone] = useState(false);
    const [confirmationSuppression, setconfirmationSuppression] = useState(false);
    const [dataToShow, setdataToShow] = useState([]);
    const [filterButtonPressed, setfilterButtonPressed] = useState(false);

    const isLogedIn = useSelector(selectLogIn);
    const formInError = useSelector(selectFormInError);
    const libelleError = useSelector(selectlibelleError);

    const rowToDelete = useRef(null);
    const rowDone = useRef(null);

    const dispatch = useDispatch();
    const allTasks = useRef(null);

    const rowTitle = [ "Task", "Date de fin", "date réelle", "", ""];

    useEffect(() => {
        if (isLogedIn) {
            setisLoading(true);
            setshowDataTable(!showDataTable);
            dispatch(getAllTasks())
            .then((res) => {
                setisLoading(false);
                if (res.payload?.success) {
                    if (res.payload.tasks) {
                        allTasks.current = res.payload.tasks;
                    } else { allTasks.current = []; }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isLogedIn]);


    const backdropClicked = () => {
        setbackdropShow(false);
        setconfirmationSuppression(false);
        setmodalTaskDone(false);
        setshowDataTable(!showDataTable);
    }
    const confirmerConfirmationSuppression = (event) => {
        event.preventDefault();
        setbackdropShow(false);
        setconfirmationSuppression(false);
        setfilterButtonPressed(false);
        setshowDataTable(!showDataTable);
        setisLoading(true);
        setshowDataTable(!showDataTable);
        dispatch(deleteTask(rowToDelete.current))
            .then((res) => {
                setisLoading(false);
                if (res.payload?.success) {
                    if (res.payload.tasks) {
                        allTasks.current = res.payload.tasks;
                    } else { allTasks.current = []; }
                    const filteredData = allTasks.current;
                    fillDataToShow(dataFilterState);
                    setshowDataTable(!showDataTable);
                }
            })

    }

    const confirmerTaskDone = async (dateDone) => {
        setbackdropShow(false);
        setmodalTaskDone(false);
        setfilterButtonPressed(false);
        setshowDataTable(!showDataTable);
        setisLoading(true);
        setshowDataTable(!showDataTable);
        const task = {tasks_id: rowDone.current, tasks_date_done: dateDone }
        dispatch(taskDone(task))
            .then((res) => {
                setisLoading(false);
                if (res.payload?.success) {
                    if (res.payload.tasks) {
                        allTasks.current = res.payload.tasks;
                    } else { allTasks.current = []; }
                    const filteredData = allTasks.current;
                    fillDataToShow(dataFilterState);
                    setshowDataTable(!showDataTable);
                }

            })


    }

    const annulerConfirmationSuppression = (event) => {
        event.preventDefault();
        setbackdropShow(false);
        setconfirmationSuppression(false);
        setshowDataTable(!showDataTable);
    }

    const annulerTaskDone = (event) => {
        event.preventDefault();
        setbackdropShow(false);
        setmodalTaskDone(false);
        setshowDataTable(!showDataTable);
    }

    const doneImageHandle = (e, row) => {
        e.preventDefault();
        rowDone.current = row;
        setbackdropShow(true);
        setmodalTaskDone(true);
        setshowDataTable(!showDataTable);

    }

    const deleteImageHandle = (e, row) => {
        e.preventDefault();
        rowToDelete.current = row;
        setshowDataTable(!showDataTable);
        setbackdropShow(true);
        setconfirmationSuppression(true);

    }

    const fillDataToShow = (filterState) => {

        if (filterState === 'done') {
            const filteredData = allTasks.current.filter((item) => {
                return item.date_done_friendly
            })
            setdataToShow(filteredData);
        }
        if (filterState === 'notdone') {
            const filteredData = allTasks.current.filter((item) => {
                return !item.date_done_friendly
            })
            setdataToShow(filteredData);
        }
        if (filterState === 'tous') {
            const filteredData = allTasks.current;
            setdataToShow(filteredData);
        }

    }

    const selectionButtonHandle = (e) => {
        e.preventDefault();
        setfilterButtonPressed(true);
        const filterState = e.target.innerHTML.toLowerCase().replace(/ /g,'');
        setdataFilterState(filterState);
        fillDataToShow(filterState);
        setshowDataTable(!showDataTable);
    }
    const newDataArray = [];
    if( !filterButtonPressed && allTasks.current && allTasks.current.length !== dataToShow.length){
        if (dataFilterState === 'tous') {
            setdataToShow(allTasks.current);
        }

    }
    for (const task of dataToShow ) {
        const deleteCell = <img src='delete.png'
                                alt='delete' width='15px'
                                className={classes.imgEnabled}
                                onClick={   (e) => deleteImageHandle(e,`${task.tasks_id}`)} />

        const imageClasss = (task.date_done_friendly
            === null) ? [classes.imgEnabled] : [classes.imgDisabled]
        const doneCell = <img   src='done.jpg'
                                alt='done' width='20px'
                                className={imageClasss.toString().replaceAll(","," ")}
                                onClick= {(e) => (task.date_done_friendly
 === null) ? doneImageHandle(e, `${task.tasks_id}`) : null} />

        const dateLimiteCell = <div><span>{task.date_limite_yyyymmddhh24miss}</span> {task.date_limite_friendly?.substring(0,task.date_limite_friendly.length-9)}</div>;

        const dateDoneCell = task.date_done_yyyymmddhh24miss === null ?'' :<div><span>{task.date_done_yyyymmddhh24miss}</span> {task.date_done_friendly?.substring(0,task.date_done_friendly.length-9)}</div>;

        newDataArray.push([task.tasks_desc,dateLimiteCell,dateDoneCell,doneCell,deleteCell])
    }

    const dataorder = { column: 1, order: 'desc'}
    const lengthMenu = [5, 25, 50, 75, 'Tous']
    const columnDefs = [
                { className: "dt-head-center", targets: [0,1,2 ] },
                { targets: [0,1,2,3,4], className: 'dt-body-center'},
                { targets: [3,4], orderable: false},
    ]
    const languageLibelle = {...DefaultLanguageOptions,
                                "search": '<i class="fa fa-filter" aria-hidden="true"></i>',
                                "searchPlaceholder": 'Filter Tasks',
                                "paginate": {
                                    "first":      "Premier",
                                    "last":       "Dernier",
                                    "next":       "Proc",
                                    "previous":   "Prec"
                                }
    };

    for (const language in DefaultLanguageOptions) {
        if (DefaultLanguageOptions.hasOwnProperty.call(DefaultLanguageOptions, language)) {
            if (typeof DefaultLanguageOptions[language] === 'string'){
                const element = DefaultLanguageOptions[language].replace('entries', 'Tasks');
                DefaultLanguageOptions[language] = element;
            }
        }
    }

    return (

        <>

            { modalTaskDone &&
                <Modal show={modalTaskDone}>
                    <TaskDone
                        annulationBtn={annulerTaskDone}
                        confirmationBtn={confirmerTaskDone}
                    />
                </Modal>
            }

            { confirmationSuppression &&
                <Modal show={confirmationSuppression}>
                    <ConfirmationSuppression
                        annulationBtn={annulerConfirmationSuppression}
                        confirmationBtn={confirmerConfirmationSuppression}
                    />
                </Modal>
            }
            { backdropShow &&
                <Backdrop show={backdropShow} clicked={backdropClicked}

                />
            }

            { isLoading && <Spinner />}

            { !isLoading &&
                <div className={classes.Tasks}>
                    <div className={classes.TasksLists} >
                        <div className={classes.Title}>
                            <div className={classes.DivSelection}>
                                { ( dataFilterState !== 'tous') &&
                                <Button btnType="Outline-Selection"
                                    clicked={selectionButtonHandle}> Tous </Button>}

                                { ( dataFilterState !== 'done') &&
                                <Button btnType="Outline-Selection"
                                    clicked={selectionButtonHandle}> Done </Button> }

                                { ( dataFilterState !== 'notdone') &&
                                <Button btnType="Outline-Selection"
                                    clicked={selectionButtonHandle}> Not Done </Button>}
                            </div>
                            <div className={classes.DivTitle}>
                                <h1><span>Tasks List</span></h1>
                            </div>
                            <div className={classes.DivButtonNewTask}>
                                <Button btnType="Normal Primary"
                                    clicked={props.newTaskButtonClick}> New Task </Button>
                            </div>
                        </div>
                        {formInError &&
                            <label className={classes.Error}>{libelleError}</label>
                        }
                        <div id='datatabletrue'>

                            { showDataTable &&
                                <TableData
                                    id={'table1'}
                                    searching={true}
                                    paging={true}
                                    pagingType={'full_numbers'}
                                    ordering={true}
                                    header={rowTitle}
                                    order={dataorder}
                                    data={newDataArray}
                                    menu={lengthMenu}
                                    languages={languageLibelle}
                                    columnDefs={columnDefs}
                                /> }
                        </div>
                        <div id='datatablefalse'>

                            { !showDataTable &&
                                <TableData
                                    id={'table1'}
                                    searching={true}
                                    paging={true}
                                    pagingType={'full_numbers'}
                                    ordering={true}
                                    header={rowTitle}
                                    order={dataorder}
                                    data={newDataArray}
                                    menu={lengthMenu}
                                    languages={languageLibelle}
                                    columnDefs={columnDefs}
                                /> }
                        </div>
                    </div>
                    <div className={classes.DisconnectButton}>
                            <div className="Button">
                                <Button btnType="Normal Info" clicked={props.disconnectButtonClick}> Disconnect </Button>
                            </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Tasks;
