import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './app/store.js';
import { Provider } from 'react-redux';
import { injectStoreRefreshToken } from './dataBaseConnexion/refreshToken/refreshToken';
import { injectStoreWithCredentials } from './dataBaseConnexion/withCredentials/withCredentials';
import { checkAuthState } from './features/credentials/credentialsSlice';

injectStoreRefreshToken(store);
injectStoreWithCredentials(store);

const savedStorage = localStorage.getItem('__Zat');
if (savedStorage) {
  store.dispatch(checkAuthState())
}


// store.dispatch(checkAuthState());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>

  </React.StrictMode>
);
