import React from 'react';

const Header = (props) => {
    let header =  [];
    props.header.map((title) => {
        header.push( <th key={Math.random()+{title}}> {title} </th>);
        return title;
    })
    return (
        <thead>
            <tr>
                {header}
            </tr>
        </thead>
    );
};

export default Header;
