import instanceRefreshToken from '../refreshToken/refreshToken';
import axiosNoCredentials from '../withOutCredentials/withOutCredentials.js';
import axiosWithCredentials from '../withCredentials/withCredentials';
import { Buffer } from 'buffer';

export const noCredentialsRequest = async (url, params) => {
    const res = await axiosNoCredentials.post(url, params);
    return res.data;
}

export const withCredentialsRequest = async (url, params) => {
    const res = await axiosWithCredentials().post(url, params);
    return res.data;
}

export const refreshToken = async () => {

    let tokens
    const url = process.env.REACT_APP_APIHOST + '/api/V1/auth/refreshtoken';
    try {
        tokens = await instanceRefreshToken().post(url,{});
    } catch (error) {
        console.log(error);
    }

    return tokens;
}

export const saveTokens = async (token, refreshToken, user,
                            tokenDuration,refreshTokenDuration) => {


    const decoy1 = {
        type: 'decoy',
        value: generateRandomBytes(128)
    };
    const decoy2 = {
        type: 'decoy',
        value: generateRandomBytes(128)
    };
    const decoy3 = {
        type: 'decoy',
        value: generateRandomBytes(128)
    };

    const expireDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + parseInt(refreshTokenDuration.slice(0,-1)));

    const obj = {
        token: token,
        refreshtoken: refreshToken,
        user: user,
        expat: expireDate,
        tokenduration: tokenDuration,
        refreshtokenduration: refreshTokenDuration
    }

    const objEncoded = Buffer.from(JSON.stringify(obj)).toString('base64');
    const decoy1Encoded = Buffer.from(JSON.stringify(decoy1)).toString('base64');
    const decoy2Encoded = Buffer.from(JSON.stringify(decoy2)).toString('base64');
    const decoy3Encoded = Buffer.from(JSON.stringify(decoy3)).toString('base64');

    // const objDecoded = JSON.parse(Buffer.from(objEncoded,'base64'));

    localStorage.setItem("__Zat", objEncoded);
    localStorage.setItem("__JHY", decoy1Encoded);
    localStorage.setItem("LNgg__", decoy2Encoded);
    localStorage.setItem("hhGGFRD__", decoy3Encoded);
}

export const removeTokens = () => {

    localStorage.removeItem("__Zat");
    localStorage.removeItem("__JHY");
    localStorage.removeItem("LNgg__");
    localStorage.removeItem("hhGGFRD__");
}

const  generateRandomBytes = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789?,/%ù$£)](["#';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
