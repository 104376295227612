import axios from 'axios'
import { renewTokens } from '../../features/credentials/credentialsSlice';
import { userDisconnect, checkAuthTimeOut } from '../../features/credentials/credentialsSlice';

let store;

export const injectStoreRefreshToken = _store => {
    store = _store
}

const axiosInstance =  () => {

    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
    })


    instance.interceptors.request.use((config) => {
        const refreshToken = store.getState().credentials.refreshToken;
        config.headers.Authorization = `bearer ${refreshToken}`;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    instance.interceptors.response.use(
        (response) => {
            store.dispatch(renewTokens(response.data));
            store.dispatch( checkAuthTimeOut(response.data.refreshtokenexpiretime));
            return response
        },
        async (err) => {
            const error = err.response;
            if (error.status===401) {
                store.dispatch(userDisconnect());
                return Promise.reject(err);
            } else {
                return Promise.reject(err);
            }
    });

    return instance;
}

export default axiosInstance;
