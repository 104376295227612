import React from 'react';

import classes from './Button.module.css';

const Button = (props) => {
    let classesName = [classes.Button] ;
    props.btnType.split(' ').map((item) => {
        return classesName.push(classes[`${item}`]) ;
    })
    classesName = classesName.toString().replaceAll(","," ");
    return (
        <button
            className={classesName}
            onClick={props.clicked}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )

};

export default Button;
