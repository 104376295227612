import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectLogIn, userDisconnect } from './features/credentials/credentialsSlice';
import LayOut from './containers/LayOut/Layout';
import Home from './containers/Home/Home';
import LogIn from './containers/LogIn/LogIn';
import Register from './containers/Register/Register';
import Tasks from './containers/Tasks/Tasks';
import NewTask from './containers/NewTask/NewTask';
import { resetForm } from './features/loading/loadingSlice';

import './App.css';

function App() {
  const screens = [];

  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLogIn);
  const [screen, setscreen] = useState({screen:'home'});

  useEffect(() => {
    if (!loggedIn){
      setscreen({screen:'home'});
    } else {
      setscreen({screen:'tasks'});
    }
  }, [loggedIn]);

  const logInButtonHandle = (event) => {
    dispatch(resetForm());
    setscreen({screen:'tasks'});
  }

  const registerButtonHandle = () => {
    dispatch(resetForm());
    setscreen({screen:'tasks'});
  }

  const disconnectButtonHandle = (event) => {
    event.preventDefault();
    dispatch(userDisconnect());
    dispatch(resetForm());
    setscreen({screen:'home'});
  }

  const logInScreenHandle = () => {
    dispatch(resetForm());
    setscreen({screen:'login'});
  }

  const registerScreenHandle = () => {
    dispatch(resetForm());
    setscreen({screen:'register'});
  }

  const homeScreenHandle = () => {
    dispatch(resetForm());
    if (loggedIn) {
      setscreen({screen:'tasks'});
    } else {
      setscreen({screen:'home'});
    }
  }

  const newTaskButtonHandle = (event) => {
    event.preventDefault();
    dispatch(resetForm());
    setscreen({screen:'newtask'});

  }

  const tasksButtonHandle = (event) => {
    event.preventDefault();
    dispatch(resetForm());
    setscreen({screen:'tasks'});
  }

  const saveButtonHandle = (e,newTaskForm) => {
    e.preventDefault();
    setscreen({screen:'tasks'});
  }

  screens['home'] = <Home
                      logInClick={logInScreenHandle}
                      registerClick={registerScreenHandle}
                    />

  screens['register'] =   <Register
                            logInClick={logInScreenHandle}
                            homeClick={homeScreenHandle}
                            registerButtonClick={registerButtonHandle}
                          />

  screens['login'] =  <LogIn
                        registerClick={registerScreenHandle}
                        homeClick={homeScreenHandle}
                        logInButtonClick={logInButtonHandle}
                      />

  screens['tasks'] =  <Tasks
                        disconnectButtonClick={disconnectButtonHandle}
                        newTaskButtonClick={newTaskButtonHandle}
                      />

  screens['newtask'] =  <NewTask
                          tasksButtonClick={tasksButtonHandle}
                          disconnectButtonClick={disconnectButtonHandle}
                          sauvegardeButtonClick={saveButtonHandle}
                        />

  const screenToShow = screens[screen.screen]

  return (

    <LayOut>
        { loggedIn && screenToShow }
        { !loggedIn && screenToShow }
    </LayOut>
  );
}

export default App;
