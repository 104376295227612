export const registerForm = {
    email: {
        elementType: 'input',
        elementConfig:{
                type: 'email',
                placeholder:'Enter Your Email'
        },
        value: '',
        validation: {
            required: true,
            isEmail: true
        },
        valid: false,
        touched: false
    },
    password: {
        elementType: 'input',
        elementConfig:{
                type: 'password',
                placeholder:'Enter Your Password'
        },
        value: '',
        validation: {
            required: true,
            minLength:5,
            confirmPassword: true
        },
        valid: false,
        touched: false
    },
    passwordconfirmation: {
        elementType: 'input',
        elementConfig:{
                type: 'password',
                placeholder:'Confirm Your Password'
        },
        value: '',
        validation: {
            required: true,
            minLength:5,
            confirmPassword: true
        },
        valid: false,
        touched: false
    }
}

export const confirmPasswords = (element, value, signInForm) => {

    switch (element) {
        case 'password':
            if (signInForm.passwordconfirmation.touched) {
                return value === signInForm.passwordconfirmation.value
            } else {
                return true
            }
        case 'passwordconfirmation':
            if (signInForm.password.touched) {
                return value === signInForm.password.value
            } else {
                return true
            }

        default:
            return true;
    }
}


// will match any string of at least 8 characters
// that contains at least one lowercase and one uppercase ASCII character
// and also at least one character from the set @#$%^&+= (in any order).
// const pattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/

// pattern for email
// const pattern = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/


// function checkRegex(string) {
//     var checkSpecial = /[*@!#%&()^~{}]+/.test(string),
//     checkUpper = /[A-Z]+/.test(string),
//     checkLower = /[a-z]+/.test(string),
//     r = false;
//     if (checkUpper && checkLower && checkSpecial) {
//         r = true;
//     }
//     return r;

// }
