const DefaultLanguageOptions = {
    "decimal":        "",
    "emptyTable":     "Aucune Tâche",
    "info":           "Affichage _START_ to _END_ of _TOTAL_ entries",
    "infoEmpty":      "Affichage 0 to 0 of 0 entries",
    "infoFiltered":   "(filtré from _MAX_ total entries)",
    "infoPostFix":    "",
    "thousands":      ",",
    "lengthMenu":     "Voir _MENU_ entries",
    "loadingRecords": "Loading...",
    "processing":     "",
    "search":         "Search:",
    "zeroRecords":    "Aucune Tâche retrouvée",
    "paginate": {
        "first":      "First",
        "last":       "Last",
        "next":       "Next",
        "previous":   "Previous"
    },
    "aria": {
        "sortAscending":  ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
    }
}

export default DefaultLanguageOptions;
